import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/download",
  },
  {
    path: "/callus",
    component: () => import("@/views/callUs"),
  },
  {
    path: "/download",
    component: () => import("@/views/Download"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
