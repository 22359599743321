<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import { webviewGetMessage } from "@/utils/appToWebview";
export default {
  name: "App",
  created() {
    // webviewGetMessage("msgFromApp", (params) => {
    //   console.log("getAppParams", params);
    //   this.appMsg = params;
    // });
    // window["bluetoothData"] = (data) => {
    //   // let params = JSON.parse(JSON.stringify(data));
    //   // callback(params);
    //   console.log("getAppParams", data);
    //   //   this.appMsg = params;
    // };
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
body::-webkit-scrollbar {
  display: none;
}
</style>
